import { Card, CardContent, List, ListItem, ListItemText, Tooltip, ListItemIcon, Typography } from "@material-ui/core";
import React from "react";
import {StyledRating, Container} from './styles';

const Languages = () => {
    return (
        <Container>
        <Card elevation={8}>
        <CardContent>
            <List>
                <ListItem style={{display: 'flex', gap: '28px'}}>
                    <ListItemText primary="Portuguese"/>
                    <Tooltip title={<Typography variant="body2" component="h6">C2 – Proficient</Typography>} arrow placement="top" style={{}}>
                        <ListItemIcon>
                                <StyledRating 
                                    name="half-rating-read" 
                                    defaultValue={6} 
                                    max={6} 
                                    readOnly
                                />
                        </ListItemIcon>
                    </Tooltip>
                </ListItem>
            </List>
            <List>
                <ListItem>
                    <ListItemText primary="English"/>
                    <Tooltip title={<Typography variant="body2" component="h6">C1 – Advanced</Typography>} arrow placement="top">
                        <ListItemIcon>
                            <StyledRating 
                                name="half-rating-read" 
                                defaultValue={5} 
                                max={6} 
                                readOnly
                            />
                        </ListItemIcon>
                    </Tooltip>
                </ListItem>
            </List>
            <List>
                <ListItem>
                    <ListItemText primary="Spanish"/>
                    <Tooltip title={<Typography variant="body2" component="h6">B1 – Intermediate</Typography>} arrow placement="top">
                        <ListItemIcon>
                            <StyledRating 
                                name="half-rating-read" 
                                defaultValue={3} 
                                max={6} 
                                readOnly
                            />
                        </ListItemIcon>
                    </Tooltip>
                </ListItem>
            </List>
            <List>
                <ListItem>
                    <ListItemText primary="French"/>
                    <Tooltip title={<Typography variant="body2" component="h6">A2 – Pre-Intermediate</Typography>} arrow placement="top">
                        <ListItemIcon>
                            <StyledRating 
                                name="half-rating-read" 
                                defaultValue={2} 
                                max={6} 
                                readOnly 
                            />
                        </ListItemIcon>
                    </Tooltip>
                </ListItem>
            </List>
        </CardContent>
    </Card> 
    </Container>
    )
}

export default Languages;
