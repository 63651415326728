import styled from 'styled-components';
import {Paper as PaperUi, Card as CardUi, Slider, ListItem} from '@material-ui/core';
import { Rating } from '@material-ui/lab';

export const Container = styled.div`
    display: flex;
    justify-content: space-evenly;
    padding: 100px;
    gap: 20px;
`;

export const Paper = styled(PaperUi)`
`;

export const Card = styled(CardUi)`
    width: 900px;
    height: 350px;
    padding: 10px;
`;

export const StyledRating = styled(Rating)`
    iconFilled: {
      color: '#FFA500',
    };
`;

export const StyledSlider = styled(Slider)`
    &&.MuiSlider-root.Mui-disabled {
        color: #9F5DD6;
        width: 40%;

        .MuiSlider-track {
            height: 6px;
            border-radius: 20px;
        }
    
        .MuiSlider-rail {
            color: #AA8CB5;
            height: 6px;
            border-radius: 20px;
        }
        .MuiSlider-thumb.Mui-disabled {
            display: none;
        } 
    }
`;

export const StyledListItem = styled(ListItem)`
    border: solid 2px black;
    display: flex; 
    justify-content: space-between;
`;