import {
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slider,
  Typography,
  withStyles,
} from "@material-ui/core";
import React from "react";
import {
  Container,
  Card,
  StyledRating,
  StyledSlider,
  StyledListItem,
} from "./styles";

const Technical = () => {
  return (
    <Container>
      <Card elevation={6}>
        <CardHeader
          component={() => (
            <Typography
              style={{
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
              }}
              component="h5"
              variant="h5"
            >
              Frontend
            </Typography>
          )}
        />
        <CardContent>
          <List>
            <ListItem>
              <ListItemText primary="React" />
              <StyledSlider
                disabled
                defaultValue={90}
                aria-labelledby="slider"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="NextJs" />
              <StyledSlider
                disabled
                defaultValue={70}
                aria-labelledby="slider"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Angular" />
              <StyledSlider
                disabled
                defaultValue={40}
                aria-labelledby="slider"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Material UI" />
              <StyledSlider
                disabled
                defaultValue={70}
                aria-labelledby="slider"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Fluent UI" />
              <StyledSlider
                disabled
                defaultValue={60}
                aria-labelledby="slider"
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
      <Card elevation={6}>
        <CardHeader
          component={() => (
            <Typography
              style={{
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
              }}
              component="h5"
              variant="h5"
            >
              Backend
            </Typography>
          )}
        />
        <CardContent>
          <List>
            <ListItem>
              <ListItemText primary="Java" />
              <StyledSlider
                disabled
                defaultValue={90}
                aria-labelledby="slider"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="NodeJs" />
              <StyledSlider
                disabled
                defaultValue={70}
                aria-labelledby="slider"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Elixir" />
              <StyledSlider
                disabled
                defaultValue={20}
                aria-labelledby="slider"
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
      <Card elevation={6}>
        <CardHeader
          component={() => (
            <Typography
              style={{
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
              }}
              component="h5"
              variant="h5"
            >
              Database
            </Typography>
          )}
        />
        <CardContent>
          <List>
            <ListItem>
              <ListItemText primary="PortgreSQL" />
              <StyledSlider
                disabled
                defaultValue={100}
                aria-labelledby="slider"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="OracleDB" />
              <StyledSlider
                disabled
                defaultValue={100}
                aria-labelledby="slider"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="MongoDB" />
              <StyledSlider
                disabled
                defaultValue={70}
                aria-labelledby="slider"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Firebase" />
              <StyledSlider
                disabled
                defaultValue={40}
                aria-labelledby="slider"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="MySQL" />
              <StyledSlider
                disabled
                defaultValue={40}
                aria-labelledby="slider"
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
      <Card elevation={6}>
        <CardHeader
          component={() => (
            <Typography
              style={{
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
              }}
              component="h5"
              variant="h5"
            >
              Version Control
            </Typography>
          )}
        />
        <CardContent>
          <List>
            <ListItem>
              <ListItemText primary="Git" />
              <StyledSlider
                disabled
                defaultValue={100}
                aria-labelledby="slider"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="SVN" />
              <StyledSlider
                disabled
                defaultValue={30}
                aria-labelledby="slider"
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
      <Card elevation={6}>
        <CardHeader
          component={() => (
            <Typography
              style={{
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
              }}
              component="h5"
              variant="h5"
            >
              DevOps
            </Typography>
          )}
        />
        <CardContent>
          <List>
            <ListItem>
              <ListItemText primary="Docker" />
              <StyledSlider
                disabled
                defaultValue={70}
                aria-labelledby="slider"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Kubernetes" />
              <StyledSlider
                disabled
                defaultValue={50}
                aria-labelledby="slider"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Openshift" />
              <StyledSlider
                disabled
                defaultValue={50}
                aria-labelledby="slider"
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Jenkins" />
              <StyledSlider
                disabled
                defaultValue={60}
                aria-labelledby="slider"
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Technical;
