import styled from 'styled-components';
import {Card as CardUi} from '@material-ui/core';

export const Container = styled.div`
    width: 100%;
    height: 1200px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Content = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: '100px'
`;

export const Card = styled(CardUi)`
    //border: solid 2px blue
    /* &&.MuiPaper-root.MuiCard-root {
        overflow: visible;
    } */
    width: 300px;
    /*
    position: relative;
    display: flex;
    justify-content: center; */
`;

export const Header = styled.div`
    border: solid 1px #96C13C;
    border-radius: 5px;
    padding: 6px;
    width: 100px;
    background-color: #fff;
    position: absolute;
    top: -20px;
`;