import styled from 'styled-components';
import SwipeableViewsUI from 'react-swipeable-views';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Intro = styled.div`
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
        a {
            color: #6B8E23;
            padding-right: 20px;
        }
    }
`;

export const ProfileImg = styled.img`
    width: 250px;
    height: 250px;
    border-radius: 50%;
    margin-bottom: 26px;
`;

export const DeskImg = styled.img`
    width: 100%;
    height: auto;
    position:absolute;
    transform: translateY(-45%);
`;

export const EletricImg = styled.img`
    width: 100%;
    height: auto;
    position:absolute;
    transform: translateY(-40%);
`;

export const PhoneImg = styled.img`
    width: 100%;
    height: auto;
    position:absolute;
    transform: translateY(-15%);
`;

export const LisbonImg = styled.img`
    width: 100%;
    height: auto;
    position:absolute;
    transform: translateY(-34%);
`;

export const Content = styled.div`
    width: 100%;
    padding: 20px;
    height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
`;

export const SwipeableViews = styled(SwipeableViewsUI)`

    & .react-swipeable-view-container {
    }

    ::-webkit-scrollbar {
        width: 6px;
        background-color: #FFF;
    }
 
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px #C5D99B;
    }
 
    ::-webkit-scrollbar-thumb {
        background-color: #96C13C;
        outline: 1px solid slategrey;
    }
`;