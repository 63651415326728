import { Box, Button, Icon, Typography } from "@material-ui/core";
import { Container } from "./styles";
import { SiMinutemailer } from "react-icons/si";
import { FiDownload } from "react-icons/fi";
import { handleRedirect } from "../../helpers/redirect";

const Contacts = () => {
  return (
    <Container>
      <Typography variant="h4">Contacts</Typography>
      <div style={{ display: "flex", gap: "40px" }}>
        <Button
          variant="outlined"
          style={{ height: "100px", width: "200px" }}
          endIcon={<SiMinutemailer style={{ marginLeft: "20px" }} />}
          onClick={() => window.open("mailto:carvalho.meo@gmail.com", "_blank")}
        >
          Email Me
        </Button>
        <Button
          variant="outlined"
          style={{ height: "100px", width: "200px" }}
          endIcon={<FiDownload style={{ marginLeft: "20px" }} />}
          onClick={() =>
            handleRedirect(
              "drive.google.com/file/d/1pcsCWCq-mk8rrckSPKSnHVWdku8B1Jbu/view?usp=sharing"
            )
          }
        >
          Download CV
        </Button>
      </div>
    </Container>
  );
};

export default Contacts;
