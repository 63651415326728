import { useState } from "react";
import {
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
  Chip,
  Avatar,
  IconButton,
  CardActions,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { Container, Card, Content } from "./styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { FaReact, FaNodeJs, FaGithub, FaDocker } from "react-icons/fa";
import { DiReact, DiMongodb } from "react-icons/di";
import {
  SiNextdotjs,
  SiRedis,
  SiKubernetes,
  SiTypescript,
  SiJest,
  SiPostgresql,
} from "react-icons/si";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { handleRedirect } from "../../helpers/redirect";
import { GostackImage, UdemyImage } from "../../assets";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
  })
);

const Projects = () => {
  const classes = useStyles();

  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);

  const handleExpandClick1 = () => {
    setExpanded1(!expanded1);
  };

  const handleExpandClick2 = () => {
    setExpanded2(!expanded2);
  };

  return (
    <Container>
      <Typography variant="h4" style={{ marginBottom: "60px" }}>
        Projects
      </Typography>
      <Content>
        <Card elevation={6}>
          <CardHeader
            title="GoStack Bootcamp"
            subheader="Rocketseat"
            action={
              <IconButton
                aria-label="settings"
                onClick={() => handleRedirect("www.rocketseat.com.br")}
              >
                <ChevronRightIcon />
              </IconButton>
            }
          />
          <CardMedia
            style={{ height: 200 }}
            component="img"
            src={GostackImage}
          />
          <CardContent
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <Typography variant="body2" color="textSecondary" component="p">
              Rocketseat's GoStack is a course where you can learn ReactJS,
              NodeJS and React Native from zero to deploy. Includes automated
              testing, continous integration, in-store publishing and all the
              important libraries and frameworks for those who want to stay
              ready for real-world challenges and stand out in the job market.
            </Typography>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              <Chip
                avatar={<FaReact />}
                label="React JS"
                variant="outlined"
                size="small"
              />
              <Chip
                avatar={<DiReact />}
                label="React Native"
                variant="outlined"
                size="small"
              />
              <Chip
                avatar={<FaNodeJs />}
                label="Node JS"
                variant="outlined"
                size="small"
              />
              <Chip
                avatar={<FaNodeJs />}
                label="Express"
                variant="outlined"
                size="small"
              />
              <Chip
                avatar={<SiJest />}
                label="Jest"
                variant="outlined"
                size="small"
              />
              <Chip
                avatar={<SiPostgresql />}
                label="PostgreSQL"
                variant="outlined"
                size="small"
              />
              <Chip
                avatar={<SiTypescript />}
                label="Typescript"
                variant="outlined"
                size="small"
              />
              <Chip
                avatar={<FaDocker />}
                label="Docker"
                variant="outlined"
                size="small"
              />
            </div>
          </CardContent>
          <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded1,
              })}
              onClick={handleExpandClick1}
              aria-expanded={expanded1}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={expanded1} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography>Go Barber App</Typography>
              <List component="nav">
                <ListItem
                  button
                  divider
                  onClick={() =>
                    handleRedirect(
                      "www.github.com/carvalhomeo/gobarber-frontend"
                    )
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <FaGithub />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Frontend" />
                </ListItem>
                <ListItem
                  button
                  divider
                  onClick={() =>
                    handleRedirect(
                      "www.github.com/carvalhomeo/gobarber-backend"
                    )
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <FaGithub />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Backend" />
                </ListItem>
                <ListItem
                  button
                  onClick={() =>
                    handleRedirect("www.github.com/carvalhomeo/gobarber-mobile")
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <FaGithub />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Mobile" />
                </ListItem>
              </List>
            </CardContent>
          </Collapse>
        </Card>
        <Card elevation={6}>
          <CardHeader
            title="Microservices with Node Js and React"
            subheader="Udemy"
            action={
              <IconButton
                aria-label="settings"
                onClick={() =>
                  handleRedirect(
                    "www.udemy.com/course/microservices-with-node-js-and-react"
                  )
                }
              >
                <ChevronRightIcon />
              </IconButton>
            }
          />
          <CardMedia
            style={{ maxHeight: 200 }}
            component="img"
            src={UdemyImage}
          />

          <CardContent
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <Typography variant="body2" color="textSecondary" component="p">
              This course tackles every major issues around microservices head
              on. From challenges with data replication to confusing unordered
              event streams, every major challenge of building microservices is
              covered. Beyond focusing on the basics of microservices, this
              course is a perfect introduction to the world of full-stack
              development.
            </Typography>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              <Chip
                avatar={<FaReact />}
                label="React JS"
                variant="outlined"
                size="small"
              />
              <Chip
                avatar={<SiNextdotjs />}
                label="Next JS"
                variant="outlined"
                size="small"
              />
              <Chip
                avatar={<FaNodeJs />}
                label="Node JS"
                variant="outlined"
                size="small"
              />
              <Chip
                avatar={<FaNodeJs />}
                label="Express"
                variant="outlined"
                size="small"
              />
              <Chip
                avatar={<DiMongodb />}
                label="Mongo"
                variant="outlined"
                size="small"
              />
              <Chip
                avatar={<SiRedis />}
                label="Redis"
                variant="outlined"
                size="small"
              />
              <Chip
                avatar={<FaDocker />}
                label="Docker"
                variant="outlined"
                size="small"
              />
              <Chip
                avatar={<SiKubernetes />}
                label="Kubernetes"
                variant="outlined"
                size="small"
              />
              <Chip
                avatar={<SiTypescript />}
                label="Typescript"
                variant="outlined"
                size="small"
              />
            </div>
          </CardContent>
          <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded2,
              })}
              onClick={handleExpandClick2}
              aria-expanded={expanded2}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={expanded2} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography>Tickets App</Typography>
              <List component="nav">
                <ListItem
                  button
                  divider
                  onClick={() =>
                    handleRedirect("www.github.com/carvalhomeo/ticketing")
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <FaGithub />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Project" />
                </ListItem>
              </List>
            </CardContent>
          </Collapse>
        </Card>
      </Content>
    </Container>
  );
};

export default Projects;
