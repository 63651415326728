import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
    }
    
    body {
        //background: #12021C;//#1C0529; //#030404;
        //color: #FFFEFE;
    }

    body, input, button {
        font-family: 'Rubik', sans-serif;
        font-size: 16px;
    }

    h1, h2, h3, h4, hanging-punctuation, strong {
        font-weight: 500;
    }

    body {
        -webkit-font-smoothing: antialiased;
    }

    button {
        cursor: pointer;
    }

    body::-webkit-scrollbar {
        width: 6px;
        background-color: #FFF;
    }
 
    body::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px #C5D99B;
    }
 
    body::-webkit-scrollbar-thumb {
        background-color: #6B8E23;
        outline: 1px solid slategrey;
    }
`;