import React from "react";
import { Timeline as TimelineMT } from "./styles";
import TimelineItem from "./TimelineItem";

const TimelineInfo = [
  {
    title: "Cegeka - Eurocontrol",
    project:
      "Support and Develop new features across multiple applications that support network management airspace operations",
    position: "Lead Frontend Developer",
    techstack: "React | Java | Minishift",
    period: "December 2022 - Current",
    location: "Remote - Belgium",
  },
  {
    title: "Cegeka - Eurocontrol",
    project:
      "Developing the digital platform which will be at the heart of the EUROCONTROL network manager going live Q4/2023. Our main objective is to tackle the increasing complexity of air traffic management, replace the ageing legacy systems with the very latest technological solutions while we look to manage the European aviation network as efficiently and sustainably as possible in the decades to come",
    position: "Lead Frontend Developer",
    techstack: "React | Angular | Java - Spring | Openshift | Docker",
    period: "July 2021 - November 2022",
    location: "Remote - Belgium",
  },
  {
    title: "VAKT Global Ltd",
    project:
      "Developing new platform features for post-trade management of commodities markets powered by blockchain, smart contracts and machine learning",
    position: "Full Stack Developer",
    techstack: "React | Java - Spring | PostgreSQL | MongoDB",
    period: "November 2019 - June 2021",
    location: "Lisbon - Portugal",
  },
  {
    title: "Aubay - Aviva France",
    project:
      "Creating a new module to support the Article 83 Retirement Plan and its integration on the insurance company partner and end-client facing web platforms",
    position: "Frontend Developer",
    techstack: "Angular",
    period: "September 2019 – October 2019",
    location: "Lisbon - Portugal",
  },
  {
    title: "Accenture - Galp Fénix",
    project:
      "Implementing and developing algorithms, services as well as portals responsible for client’s management, data record and billing for the B2C renewable energy market",
    position: "Full Stack Developer",
    techstack: "HTML | Javascript | Java | Oracle DB | Oracle CCB",
    period: "September 2016 – August 2019",
    location: "Lisbon - Portugal",
  },
  {
    title: "Accenture - CTT Adds",
    project:
      "Creating new features for a web platform that allow companies to carry out marketing campaigns through several communication channels, such as letters, text messages and e-mails",
    position: "Full Stack Developer",
    techstack: "HTML | Javascript | Java | Spring",
    period: "July 2016 – August 2016",
    location: "Lisbon - Portugal",
  },
  {
    title: "Accenture – EDP SMILE",
    project:
      "Developing, implementing and testing new features, massive data extraction to generate reports through the database for the B2B renewable energy market. Customer support and team management",
    position: "Backend Developer",
    techstack: "HTML | Javascript | Java | Oracle DB | Oracle CCB",
    period: "June 2015 – June 2016",
    location: "Lisbon - Portugal",
  },
];

const Timeline = () => {
  return (
    <TimelineMT align="alternate">
      {TimelineInfo.map((info, index) => (
        <TimelineItem
          key={index}
          title={info.title}
          project={info.project}
          position={info.position}
          techstack={info.techstack}
          period={info.period}
          location={info.location}
        />
      ))}
    </TimelineMT>
  );
};

export default Timeline;
