import styled from 'styled-components';
import {Rating} from '@material-ui/lab';

export const Container = styled.div`
    padding: 100px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledRating = styled(Rating)`
    iconFilled: {
      color: '#FFA500',
    }
`;