import {Paper, Tooltip, Typography} from '@material-ui/core';
import { TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import {TimelineItem as TimelineItemUI} from './styles';
import React from 'react';
import PublicIcon from '@material-ui/icons/Public';

interface TimelineItemProps {
    key: number;
    title?: string;
    project?: string;
    position?: string;
    techstack?: string;
    period?: string;
    location: string;
}

const TimelineItem = ({key, title, project, position, techstack, period, location}: TimelineItemProps) => {
    return (
        <TimelineItemUI>
            <TimelineOppositeContent>
                <Typography variant="body1" color="textSecondary">
                    {period}
                </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator >
                <TimelineDot style={{backgroundColor: '#96C13C'}} />
                <TimelineConnector style={{backgroundColor: '#6B8E23'}} />
            </TimelineSeparator>
            <TimelineContent style={{textAlign: 'center'}}>
                <Paper elevation={5} style={{padding: '12px'}}>
                    <div style={{display: 'inline-flex', gap: '12px', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h5">{title}</Typography>
                        <Tooltip title={location} arrow placement="top">
                            <PublicIcon height={10} fontSize="default" style={{color: '#71B6E7'}} />
                        </Tooltip>
                    </div>
                    <Typography>
                        <Typography variant="h6" style={{color: '#6B8E23'}}>Project</Typography> {project}
                    </Typography>
                    <Typography>
                        <Typography variant="h6" style={{color: '#6B8E23'}}>Position</Typography> {position}
                    </Typography>
                    <Typography>
                        <Typography variant="h6" style={{color: '#6B8E23'}}>Tech Stack</Typography> {techstack}
                    </Typography>
                </Paper>
                </TimelineContent>
            </TimelineItemUI>
    )
}

export default TimelineItem;
