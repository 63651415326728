import {
  ProfileImage,
  DeskImage,
  LisbonImage,
  PhonesImage,
  HouseImage,
} from "../../assets/index";

import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  GitHub as GitHubIcon,
  LinkedIn as LinkedInIcon,
} from "@material-ui/icons";

import Link from "@material-ui/core/Link";

import {
  ProfileImg,
  Container,
  Intro,
  DeskImg,
  LisbonImg,
  Content,
  SwipeableViews,
  EletricImg,
  PhoneImg,
} from "./styles";
import React from "react";
import Timeline from "../../components/Timeline";
import {
  AppBar,
  createMuiTheme,
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@material-ui/core";
import Languages from "../../components/Languages";
import Technical from "../../components/Technical";
import Projects from "../../components/Projects";
import Contacts from "../../components/Contacts";

const Home = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(1);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <div>{children}</div>}
      </div>
    );
  }

  function a11yProps(index: any) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const theme2 = createMuiTheme({
    overrides: {
      MuiTabs: {
        indicator: {
          backgroundColor: "#6B8E23",
        },
      },
      MuiTab: {
        selected: {
          color: "#6B8E23",
          "&:hover": {
            color: "#6B8E23",
          },
        },
      },
    },
  });

  const someStyle = {
    "::-webkit-scrollbar": {
      width: "6px",
      backgroundColor: "#FFF",
    },

    "::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px #C5D99B",
    },

    "::-webkit-scrollbar-thumb": {
      backgroundColor: "#96C13C",
      outline: "1px solid slategrey",
    },
  };

  return (
    <Container>
      <Intro>
        <ProfileImg src={ProfileImage} alt="Profile" />
        <Typography variant="h4" component="h1">
          Hey! I'm <b>Marcel</b>
        </Typography>
        <div style={{ marginTop: "30px" }}>
          <p style={{ fontSize: "18px" }}>
            A full stack developer striving to improve and learn new things.
          </p>
          <p style={{ fontSize: "18px" }}>
            With over 8 years of experience working with a variety of
            technologies.
          </p>
        </div>
        <div style={{ marginTop: "30px" }}>
          <Link
            href="https://www.linkedin.com/in/marcelcarvalho"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedInIcon />
          </Link>
          <Link
            href="https://github.com/carvalhomeo"
            target="_blank"
            rel="noreferrer"
          >
            <GitHubIcon />
          </Link>
          {/* <Link href="https://www.facebook.com/meocarvalho"
                target="_blank"
                rel="noreferrer">
                    <FacebookIcon />
                </Link> */}
          <Link
            href="https://www.instagram.com/meocarvalho"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </Link>
        </div>
      </Intro>
      <div
        style={{
          width: "100%",
          height: "50vh",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <DeskImg src={DeskImage} alt="Desk" />
      </div>
      <Content>
        <MuiThemeProvider theme={theme2}>
          <AppBar
            position="static"
            color="transparent"
            style={{ boxShadow: "none" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab
                disableRipple
                label="Technical Competences"
                {...a11yProps(0)}
                style={{ color: "#6B8E23" }}
              />
              <Tab
                disableRipple
                label="Professional Experience"
                {...a11yProps(1)}
                style={{ color: "#6B8E23" }}
              />
              <Tab
                disableRipple
                label="Languages"
                {...a11yProps(2)}
                style={{ color: "#6B8E23" }}
              />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0}>
              <Technical />
            </TabPanel>
            <TabPanel value={value} index={1} style={someStyle}>
              <Timeline />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Languages />
            </TabPanel>
          </SwipeableViews>
        </MuiThemeProvider>
      </Content>
      <div
        style={{
          width: "100%",
          height: "50vh",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <LisbonImg src={LisbonImage} alt="Lisbon" />
      </div>
      <Projects />
      <div
        style={{
          width: "100%",
          height: "50vh",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <PhoneImg src={HouseImage} alt="Phone" />
      </div>
      <Contacts />
    </Container>
  );
};

export default Home;
